import axios from "axios";

const CACHE_KEY = "userLocationInfo";
const CACHE_EXPIRATION_TIME = 2 * 24 * 60 * 60 * 1000;

export const getUserLocationData = async () => {
    const cachedDataString = localStorage.getItem(CACHE_KEY);

    const now = new Date().getTime();

    if (cachedDataString) {
        const cachedData = JSON.parse(cachedDataString);

        if (now - cachedData.timestamp < CACHE_EXPIRATION_TIME) {
            return cachedData.data;
        }
    }

    try {
        const response = await axios.get("https://api.country.is");

        const cacheObject = {
            data: response.data,
            timestamp: now
        };

        localStorage.setItem(CACHE_KEY, JSON.stringify(cacheObject));

        return response.data;
    } catch (error) {
        throw error;
    }
};
