import React from "react";
import "./src/styles/global.css";
import {LocationInfoProvider} from "./src/context/LocationInfoContext";
import ReactDOM from "react-dom/client";
import {UTMProvider} from "./src/context/utmContext";
import {setUTMParameters} from "./src/utils/utmSetter";

export const wrapRootElement = ({ element }) => (
    <LocationInfoProvider>
        <UTMProvider>
        {element}
        </UTMProvider>
    </LocationInfoProvider>
);

export const replaceHydrateFunction = () => {
    return (element, container) => {
        const root = ReactDOM.createRoot(container);
        root.render(element);
    };
};

export const onRouteUpdate = () => {
    setUTMParameters();
};

export const onClientEntry = () => {
    if (typeof window !== "undefined") {
        const userLanguage = navigator.language || navigator.languages[0];
        const languageCode = userLanguage.split("-")[0];

        const supportedLanguages = ["en", "ar"];
        const defaultLanguage = supportedLanguages.includes(languageCode) ? languageCode : "en";

        const pathSegments = window.location.pathname.split("/");
        const isLanguageInPath = supportedLanguages.includes(pathSegments[1]);

        if (!isLanguageInPath) {
            const newPath = `/${defaultLanguage}${window.location.pathname}`;
            window.location.replace(newPath);
        }
    }
};
