import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';

const UTMContext = createContext();

const parseUrlParams = () => {
    const params = new URLSearchParams(window.location.search);
    const utmParams = {};
    params.forEach((value, key) => {
        if (key.startsWith('utm_')) {
            utmParams[key] = value;
        }
    });
    return utmParams;
};


export const useUTM = () => {
    const context = useContext(UTMContext);

    if (typeof window !== 'undefined') {
        return context;
    } else {
        return { country: {}, updateCountry: () => {}, isLoading: true };
    }
};

export const UTMProvider = ({ children }) => {
    const [utm, setUtm] = useState(parseUrlParams());

    useEffect(() => {
        setUtm(parseUrlParams());
    }, [window.location.search]);

    const value = useMemo(() => ({ utm, setUtm }), [utm]);

    return (
        <UTMContext.Provider value={value}>
            {children}
        </UTMContext.Provider>
    );
};

