import React, { createContext, useState, useEffect } from "react";
import {countries} from "../utils/countries";
import {getUserLocationData} from "../services/locationApi";

const LocationInfoContext = createContext();

const defaultCountry = countries.find(country => country.code === 'INT');
const isSSR = typeof window === 'undefined';

const LocationInfoProvider = ({ children }) => {
    const [country, setCountry] = useState(defaultCountry);
    const [isLoading, setIsLoading] = useState(true);
    let countryInfo = {};
    const fetchLocationData = async () => {
        try {
            if (isSSR) {
                countryInfo = countries.find(country => country.code === 'SA');
                setCountry(countryInfo);
                setIsLoading(false);
                return;
            }
            const data = await getUserLocationData();
            const countryCode = data.country;

            switch (countryCode) {
                case "SA":
                    countryInfo = countries.find(country => country.code === 'SA');
                    break;
                case "AE":
                    countryInfo = countries.find(country => country.code === 'AE');
                    break;
                case "QA":
                    countryInfo = countries.find(country => country.code === 'QA');
                    break;
                case "KW":
                    countryInfo = countries.find(country => country.code === 'KW');
                    break;
                    case "OM":
                    countryInfo = countries.find(country => country.code === 'OM');
                    break;
                    case "BH":
                    countryInfo = countries.find(country => country.code === 'BH');
                    break;
                    case "INT":
                    countryInfo = countries.find(country => country.code === 'INT');
                    break;
                default:
                    countryInfo = countries.find(country => country.code === 'INT');
            }

            setCountry(countryInfo);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setCountry(defaultCountry);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchLocationData();
    }, []);

    const updateCountry = (selectedCountry) => {
        setCountry(selectedCountry);
    };
    const contextValue = { country, isLoading, updateCountry };
    return (
        <LocationInfoContext.Provider value={contextValue}>
            {children}
        </LocationInfoContext.Provider>
    );
};

export { LocationInfoContext, LocationInfoProvider };
