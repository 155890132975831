export const setUTMParameters = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmSource = urlParams.get("utm_source");
    const utmMedium = urlParams.get("utm_medium");
    const utmCampaign = urlParams.get("utm_campaign");

    if (utmSource && utmMedium && utmCampaign) return;

    const referrer = document.referrer;

    let newParams = "";

    if (referrer) {
        if (referrer.includes("google.com")) {
            newParams = "?utm_source=google&utm_medium=organic&utm_campaign=(organic)";
        } else if (referrer.includes("yahoo.com")) {
            newParams = "?utm_source=yahoo&utm_medium=organic&utm_campaign=(organic)";
        } else if (referrer.includes("bing.com")) {
            newParams = "?utm_source=bing&utm_medium=organic&utm_campaign=(organic)";
        }
    }

    if (newParams) {
        const newUrl = window.location.pathname + newParams;
        window.history.replaceState({}, document.title, newUrl);
    }
};
